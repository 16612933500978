import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Importing Images
import bgLogistic from "./bgLogistic.png";
import bgBank from "./bgBank.png";
import bgRetail from "./bgRetail.png";
import bgManufacturing from "./bgManufacturing.png";
import bgLogisticSm from "./bgLogisticSm.png";
import bgBankSm from "./bgBankSm.png";
import bgRetailSm from "./bgRetailSm.png";
import bgManufacturingSm from "./bgManufaturingSm.png";

import "./swiper.css";
import { Link } from "react-router-dom";

import HeroBgImage2 from "./HeroBgImage2.png";
// import MobileHeroBgImage2 from "./MobileHeroBgImage2.png";
import { useState, useEffect } from "react";

import bgLandingPage from "../../assets/HeroBgImage2.png";
import MobileHeroBgImage2 from "../../assets/MobileHeroBgImage2.png";

// Custom Hook to detect screen size
const useMediaQuery = (width) => {
  const [isScreenSize, setIsScreenSize] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => setIsScreenSize(window.innerWidth < width);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return isScreenSize;
};

const NewIndustry = () => {
  // Using hook to detect small devices
  const isSmallDevice = useMediaQuery(768); // Example for mobile devices, adjust as necessary

  // Define industries data with images for small and large screens
  const industriesData = [
    {
      id: 1,
      title: "Logistics",
      description:
        "We provide comprehensive logistics solutions designed to streamline your supply chain. With real-time tracking and a dedicated team, we ensure your shipments are handled efficiently and delivered on time. ",
      image: isSmallDevice ? bgLogisticSm : bgLogistic,
      bgColor: "bg-red-500",
      borderColor: "border-red-500",
    },
    {
      id: 2,
      title: "Manufacturing",
      description:
        "We pride ourselves on delivering high-quality manufacturing solutions tailored to meet diverse industry needs. Our advanced technologies and skilled workforce ensure precision, efficiency, and consistent product quality.",
      image: isSmallDevice ? bgManufacturingSm : bgManufacturing,
      bgColor: "bg-blue-500",
      borderColor: "border-blue-500",
    },
    {
      id: 3,
      title: "Banking",
      description:
        "We provide comprehensive banking solutions designed to enhance financial services and customer satisfaction. Our innovative technology ensures secure transactions, efficient operations, and personalized banking experiences. ",
      image: isSmallDevice ? bgBankSm : bgBank,
      bgColor: "bg-green-500",
      borderColor: "border-green-500",
    },
    {
      id: 4,
      title: "Retail",
      description:
        "We offer innovative solutions designed to enhance the retail experience and streamline operations. Our technology empowers retailers to optimize inventory management, improve customer engagement, and drive sales growth. ",
      image: isSmallDevice ? bgRetailSm : bgRetail,
      bgColor: "bg-purple-500",
      borderColor: "border-purple-500",
    },
  ];

  const [isTablet, setIsTablet] = useState(window.innerWidth < 768);
  const [isMobile, setIsMobile] = useState( window.innerWidth <= 425);
 
   useEffect(() => {
     const handleResize = () => {
       setIsMobile(window.innerWidth < 768);
       setIsTablet(window.innerWidth >= 768 && window.innerWidth < 425);
     };
 
     window.addEventListener("resize", handleResize);
     return () => window.removeEventListener("resize", handleResize);
   }, []);
  return (
    <div className="h-full w-full">
      {/* Page Header Section */}
      <div className="relative flex flex-col items-center justify-center gap-2 md:gap-0   h-[400px] md:h-[560px] lg:h-screen overflow-hidden bg-white dark:bg-black z-0">
        {/* Half Moon */}
        <div
        className={`absolute inset-0 ${
          isMobile ? "bg-contain" : "bg-cover" || isTablet ? "bg-contain" : "bg-cover "
        } bg-center bg-no-repeat`}
        style={{
          backgroundImage: `url(${
            isMobile ? MobileHeroBgImage2 : bgLandingPage
            
          })`,
        }}
      />

        <h1 className="text-2xl md:text-5xl lg:text-7xl font-semibold mt-10 lg:mt-0 text-black dark:text-white ">
          Industries
        </h1>

        {/* <div className="mt-10 md:hidden"></div> */}

        <p className="text-black dark:text-white text-md md:text-2xl lg:text-4xl font-semibold text-center w-[70%]  lg:px-2 md:mt-6">
          Solutions that Work for You
        </p>
        <p className="absolute z-50 dark:text-white bottom-0 md:bottom-0 text-xs md:text-xl xl:text-2xl 2xl:text-3xl w-[74%] font-normal text-center">
          From innovative technologies to smart strategies, we help businesses grow, adapt , and lead in a changing world.
        </p>
      </div>

      {/* Industries Swiper Section */}
      <div className="relative p-4 pt-[20%] md:pt-[5%] h-[600px] md:h-[750px]">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination]}
        >
          {industriesData.map((industry) => (
            <SwiperSlide key={industry.id}>
              <div className={`h-[450px] md:h-[600px] rounded-md `}>
                <div
                  className={`rounded-md border-4 ${industry.borderColor} ${industry.bgColor}`}
                >
                  <img
                    src={industry.image}
                    alt={industry.title}
                    className="w-full relative h-[300px] md:h-[500px] object-cover"
                  />
                  {/* Text Content */}
                  <div className="absolute  bottom-[50px] md:bottom-0 right-0 md:right-[2%] lg:right-[6%] ">
                    <div
                      className={`text-white p-4 md:p-6 rounded-2xl max-w-[270px] md:max-w-[320px] ${industry.bgColor}`}
                    >
                      <h2 className="text-lg md:text-2xl font-bold mb-2">
                        {industry.title}
                      </h2>
                      <p className="mb-4 text-xs md:text-sm">
                        {industry.description}
                      </p>
                      <Link to={"/contactus"}>
                        {" "}
                        <button className="bg-white text-black text-sm md:text-lg px-2 py-1 md:px-4  md:py-2  rounded-full">
                          Get Started →
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default NewIndustry;
